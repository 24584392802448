import { useFetcher } from '@remix-run/react'

export function ThemeForm({ children }: { children: React.ReactNode }) {
  const fetcher = useFetcher()

  return (
    <fetcher.Form action="/settings" method="post">
      <input name="preferenceName" type="hidden" value="Theme" />
      {children}
    </fetcher.Form>
  )
}
